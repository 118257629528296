import React from 'react'

import 'twin.macro'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { motion } from 'framer-motion'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'

import { Parallax } from './parallax'
import {
  containerVariants,
  itemVariants,
  MxSectionContainer,
  MxSectionFirstContainer,
  MxSectionSecondContainer,
} from './section-components'
import useDisplay from '../hooks/useDisplay'
import { Section } from '../section'

interface SpecsSectionProps {
  data: {
    contentSlots: {
      markdown?: RenderRichTextData<ContentfulRichTextGatsbyReference>
      slotTitle: string
    }[]
    images?: {
      title?: string
      localFile?: {
        childImageSharp?: {
          gatsbyImageData?: IGatsbyImageData
        }
      }
    }[]
    moduleTitle?: string
    moduleMisc?: {
      description?: string
    }
  }
}
export const SpecsSection = ({ data }: SpecsSectionProps) => {
  const intersectionRef = React.useRef(null)
  const showIt = useDisplay(intersectionRef)
  const styleContent = data.contentSlots
  return (
    <Section tw="mt-44 max-w-full" padding="none">
      <div tw="flex flex-col w-full self-stretch relative bg-gray-100 items-center py-20 px-8 md:px-0">
        <MxSectionContainer tw="mb-0 max-w-screen-xl">
          <div tw="col-span-full pb-24 md:(col-start-3 col-end-15)">
            <h2 tw="font-thin leading-tight text-4xl mx-auto text-center text-gray-900">
              {data.moduleTitle}
            </h2>
            <p tw="text-base max-w-lg font-light text-gray-900 leading-snug mx-auto text-center md:max-w-3xl">
              {data.moduleMisc?.description}
            </p>
          </div>
          <MxSectionFirstContainer tw="md:(col-start-2 col-end-6)">
            <div tw="flex w-full justify-center relative" ref={intersectionRef}>
              <Parallax tw="w-full mx-auto flex flex-row flex-wrap relative md:mx-0">
                <GatsbyImage
                  alt={data.images?.[0]?.title ?? ''}
                  image={
                    data.images?.[0]?.localFile?.childImageSharp
                      ?.gatsbyImageData as IGatsbyImageData
                  }
                />
              </Parallax>
            </div>
          </MxSectionFirstContainer>
          <MxSectionSecondContainer tw="row-start-auto col-span-full gap-0 px-4 pt-12 grid items-center md:(col-start-6 px-0 pt-0)">
            <div tw="col-span-full relative md:(col-start-2)">
              <div tw="flex justify-center items-center">
                <motion.div
                  variants={containerVariants}
                  initial="hidden"
                  animate={showIt ? 'show' : 'hidden'}
                  tw="w-full mx-auto flex flex-row flex-wrap space-y-10 md:(mx-0 space-y-0 gap-y-14)"
                >
                  {styleContent.map((item: any, index: number) => {
                    return (
                      <motion.div
                        key={index}
                        variants={itemVariants}
                        tw="w-full flex flex-row items-center items-center p-2 md:(w-1/2 px-7)"
                      >
                        <div>
                          <p tw="text-black text-2xl font-thin leading-tight pb-2">
                            {item?.slotTitle}
                          </p>
                          {renderRichText(item.markdown, {
                            renderNode: {
                              [BLOCKS.PARAGRAPH]: (node, children) => {
                                if (node.nodeType === 'paragraph') {
                                  return (
                                    <p tw="text-sm text-gray-600">{children}</p>
                                  )
                                }
                              },
                              [INLINES.HYPERLINK]: (
                                { data: { uri } },
                                node,
                              ) => {
                                return (
                                  <a
                                    className="link"
                                    onClick={() => window.open(uri)}
                                  >
                                    {node}
                                  </a>
                                )
                              },
                            },
                            renderText: (text) =>
                              text
                                .split('\n')
                                .flatMap((text, i) => [
                                  i > 0 && <br key={i} />,
                                  text,
                                ]),
                          })}
                        </div>
                      </motion.div>
                    )
                  })}
                </motion.div>
              </div>
            </div>
          </MxSectionSecondContainer>
        </MxSectionContainer>
      </div>
    </Section>
  )
}
