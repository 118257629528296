import React from 'react'

import 'twin.macro'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Text as ContentfulText } from '@contentful/rich-text-types/dist/types/types'
import { motion } from 'framer-motion'
import { navigate } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import tw from 'twin.macro'

import fireEvent from 'context/tracking/events'
import ListStats, { ListStatsItem } from 'mx/components/list-stats'

import {
  MxSectionContainer,
  WebsiteButton as Button,
} from '../components/section-components'
import { Section } from '../section'

const variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { ease: 'easeOut', delay: 0.25, duration: 0.75 },
  },
  hidden: { opacity: 0, y: 75 },
}

interface HeroProps {
  badges?: boolean
  data: {
    contentSlots: {
      actionText: string
      actionUrl: string
      description: string
      markdown: RenderRichTextData<ContentfulRichTextGatsbyReference>
      photos?: {
        title?: string
        file?: {
          url?: string
        }
        localFile?: {
          childImageSharp?: {
            gatsbyImageData?: IGatsbyImageData
          }
        }
      }[]
      slotTitle: string
    }[]
  }
  graphicImage?: boolean
  listStats?: ListStatsItem[]
  logoImage?: boolean
}
const Hero = ({
  badges,
  data,
  graphicImage,
  listStats: items,
  logoImage,
}: HeroProps) => {
  const heroContent = data.contentSlots[0]
  return (
    <Section padding="none" tw="max-w-full">
      <motion.div initial="hidden" animate="visible" variants={variants}>
        {heroContent.photos && !logoImage ? (
          <div
            tw="w-full h-auto md:h-128"
            css={badges ? tw`!relative md:!max-h-128` : undefined}
          >
            {badges && (
              <div tw="flex flex-row w-full items-center justify-center space-x-8 absolute z-10 bottom-6 md:bottom-7 ">
                {heroContent.photos
                  ?.slice(1, 5)
                  .map((badge, index) => (
                    <img
                      key={index}
                      tw="h-10 md:h-13"
                      src={badge?.file?.url}
                      alt={badge?.title}
                    />
                  ))}
              </div>
            )}

            <GatsbyImage
              alt={heroContent.photos?.[0]?.title ?? ''}
              css={tw`max-h-full w-full`}
              imgStyle={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              image={
                heroContent.photos?.[0]?.localFile?.childImageSharp
                  ?.gatsbyImageData as IGatsbyImageData
              }
            />
          </div>
        ) : null}
        <div tw="mx-auto flex flex-col w-full max-w-screen-xl pt-8 pb-0 text-center items-center space-y-10 md:(space-y-8 pt-20 pb-4)">
          <MxSectionContainer tw="mb-0">
            <div tw="col-span-full px-4 space-y-10 md:(col-start-2 col-end-16 px-0 space-y-8)">
              <div tw="w-[max-content] border-b border-gray-500 mx-auto">
                <div tw="py-2 text-center text-gray-600 text-sm font-light leading-loose tracking-widest">
                  {heroContent.description}
                </div>
              </div>

              <div tw="space-y-10 md:space-y-7">
                <h1 tw="leading-tight font-thin text-4xl text-black">
                  {heroContent.slotTitle}
                </h1>

                {heroContent.markdown &&
                  renderRichText(heroContent.markdown, {
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => {
                        const { value: textValue } = node
                          .content[0] as ContentfulText
                        if (node.nodeType === 'paragraph' && textValue) {
                          return (
                            <p tw="mx-auto leading-loose font-light text-base text-gray-600 md:text-lg">
                              {children}
                            </p>
                          )
                        }
                      },
                      [INLINES.HYPERLINK]: ({ data: { uri } }, node) => {
                        return (
                          <a className="link" onClick={() => window.open(uri)}>
                            {node}
                          </a>
                        )
                      },
                    },
                    renderText: (text) =>
                      text
                        .split('\n')
                        .flatMap((text, i) => [i > 0 && <br key={i} />, text]),
                  })}

                {heroContent?.actionText && (
                  <Button
                    color="transparentBlack"
                    rounded
                    onClick={() => {
                      fireEvent({
                        type: 'cta_clicked',
                        ctaStr: `${heroContent?.actionText} // Hero CTA`,
                      })
                      navigate(heroContent.actionUrl)
                    }}
                  >
                    {heroContent?.actionText}
                  </Button>
                )}
              </div>
            </div>
          </MxSectionContainer>
          {logoImage && (
            <div tw="w-8/12 md:w-3/12 !mt-32 h-auto flex justify-center ">
              <img
                tw="max-h-full w-full"
                src={heroContent.photos?.[0]?.file?.url}
                alt={`${heroContent.photos?.[0]?.title}`}
              />
            </div>
          )}
          {graphicImage && (
            <div tw="w-full h-auto flex justify-center !mt-44">
              <img
                tw="hidden md:(w-8/12 block)"
                src={heroContent.photos?.[1]?.file?.url}
                alt={`${heroContent.photos?.[1]?.title}`}
              />
              <img
                tw="w-7/12 block md:hidden"
                src={heroContent.photos?.[2]?.file?.url}
                alt={`${heroContent.photos?.[2]?.title}`}
              />
            </div>
          )}
        </div>
      </motion.div>
      {items && (
        <div tw="mx-auto flex flex-col w-full max-w-screen-xl justify-center px-6 md:px-0">
          <div tw="grid grid-cols-6 md:grid-cols-16">
            <div tw="col-span-full md:(col-start-2 col-end-16)">
              <div tw="flex justify-center">
                <ListStats items={items} padding={false} />
              </div>
            </div>
          </div>
        </div>
      )}
    </Section>
  )
}

export default Hero
